import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import pulse from "../../assets/images/pulseBg.png";
const classes = require('./About.module.scss');

const about = () => {
  return (
    <div className={classes.AboutPage}>
      <Helmet>
        <title>About</title>
        <meta name="description" content="" />
      </Helmet>

    </div>
  );
}
export default about;