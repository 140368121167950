import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import Social from "../../components/Social/Social";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import image from "../../assets/images/contactImage.jpg";
const classes = require('./Contact.module.scss');



const contact = (props) => {
  
  const OuterTitle = (<h1>Emerald Coast Foundation <br/><span>Get in Touch</span></h1>);
  const FlyerContent = (
    <div className={classes.med4Def}>
      <p className={classes.bmargin1}>The Emerald Coast Poker Run would not be possible without the help of hundreds of volunteers that work tirelessly throughout the year, 
      helping to organize our events. <b>If you are interested in becoming a volunteer, or if would like to learn more about our organization, 
        use the contact information or the form below to get in touch.</b> 
      </p>
    </div>
  );
  const fwRow = (<img src={image} alt="" />);
  const FlyerContent2 = (
    <table><tbody>
      <tr>
        <td>
          <p><span className={classes.cat}>Phone</span><br/><Phone where="contact" /></p>
          <p><span className={classes.cat}>Email</span><br/><Email where="contact" /></p>
          <p><span className={classes.cat}>Mailing</span><br/>P.O. Box 1208<br/> Fort Walton Beach, Fl 32549 </p>
        </td>

        <td>
        <p className={classes.bmargin0}><span className={classes.cat}>Follow</span></p><Social where="contact" />
          <p className={classes.bmargin0}><span className={classes.cat}>Donations</span></p>
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank" className={classes.ppForm} >
            <input type="hidden" name="cmd" value="_s-xclick"/>
            <div className={classes.clearfix}/>
            <input type="hidden" name="hosted_button_id" value="N3BMNHW44TFUC"/>
            <div className={classes.clearfix}/>
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" className={classes.ppBadge} />
            <br/>
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
          </form>
        </td>
      </tr>
      
      <tr>
        <td>
          <p><span>Contact Form</span></p>
          <script type="text/javascript" src="https://form.jotform.com/jsform/212215146392045"></script>
          <iframe
            id="JotFormIFrame-212215146392045"
            title="Emerald Coast Foundation Contact Form"
            // onLoad="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowFullScreen={true}
            src="https://form.jotform.com/212215146392045"
            frameBorder="0"
            style={{minWidth:'100%',height:'auto',border:'none'}}
            scrolling="no"
          >
          </iframe>
        </td>
      </tr>
    </tbody></table>
  );

  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Contact The Emerald Coast Foundation</title>
        <meta name="description" content="The Emerald Coast Poker Run would not be possible without some amazing individuals, volunteers, and businesses. Contact the Emerald Coast Foundation if you are interested in being a part of this event!" />
      </Helmet>
    
      <FlyerLay where="ContactPage" OuterTitle={OuterTitle} InnerTitle={FlyerContent} fwRow={fwRow} FlyerContent2={FlyerContent2} InnerWrap="med4Def" ContentClass="FlyerContent2" />
    </div>
  );
}
export default contact;