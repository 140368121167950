import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./Hotels.module.scss";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
// import EventSubnav from "../../components/EventSubnav/EventSubnav";
import island from "../../assets/images/sponsors/lodgeIslandResort.png";
import destin from "../../assets/images/sponsors/lodgeDestinHarbor.png";

const hotels = (props) => {
  const OuterTitle=(<h1>Emerald Coast Poker Run <br/><span>Lodging Partners</span></h1>);
  const InnerTitle=(
    <div>
      <a href="#island" data-uk-scroll>The Island Resort</a>
      <a href="#harbor" data-uk-scroll>The Inn at Destin Harbor</a>
    </div>
  );
  
  const FlyerContent = (
    <table><tbody>
      <tr>
        <td id="island">
          <a href="https://be.synxis.com/?adult=1&arrive=2024-08-08&chain=6255&child=0&currency=USD&depart=2024-08-11&group=2408ECPOKR&hotel=37398&level=hotel&locale=en-US&rooms=1" target="_blank" rel="noreferrer">
            <img src={island} alt="The Island Resort logo"/>
          </a>

          <h3>Book Online:</h3>
          <p>Click the link below, select the dates and room type you are interested in on the booking screen, and click BOOK NOW!</p>
          <a 
            href="https://be.synxis.com/?adult=1&arrive=2024-08-08&chain=6255&child=0&currency=USD&depart=2024-08-11&group=2408ECPOKR&hotel=37398&level=hotel&locale=en-US&rooms=1" 
            target="_blank" 
            rel="noreferrer" 
            className={[classes.ecLink, classes.ecLinkSm].join(' ')}
          >Book Online</a>
          <div className={classes.clearfix} /><div className={classes.spacer2} />

          <h3>Book by Phone:</h3>
          <p><b>Book Monday-Friday: 8am-5pm CDT</b><br/>
            <a href="tel:+18503379194">850-337-9194</a> 
          </p>

          <p><b>Book 24/7!</b><br/>
            <a href="+18008748962">1-800-874-8962</a>
          </p>
          
          <h3>Event Details:</h3>
          <p>
            Start date: 8/8/{props.year}<br/>
            End date: 8/11/{props.year}<br/>
            Last day to book: 7/8/24<br/>
            Cancellation Policy: 48 hours prior to arrival<br/>
            Deposit Policy: 1 Night due at booking<br/>
            Rate: starting at $199.00 USD per night plus tax
          </p>
            
            
          <h3>Please note:</h3> 
          <p>Guest's need to contact the group housing coordinator directly, if they want to extend their stay or book different room types outside of the group 
            block (which is subject to availability and rate). Reservations needed after the cutoff date must be booked directly through group housing. The group rate after cutoff is 
            not guaranteed, and is subject to current availability.
          </p>

          <p>
            <b>Group Housing Coordinator:</b><br/>
            Venessa Blackmon - Available Monday-Friday: 8am-5pm CDT<br/>
            Please Call <a href="tel:+18503379194">850-337-9194</a>, or email <a href="mailto:vblackmon@theislandfl.com">vblackmon@theislandfl.com</a>
          </p>
        </td>

        <td id="harbor">
          <a href="https://www.choicehotels.com/florida/destin/ascend-hotels/flf10?mc=llgoxxpx" target="_blank" rel="noreferrer"><img src={destin} alt="The Island Resort logo"/></a>
          <h3>Book Online:</h3>
          <p>Click the link below to book online!</p>
          <a href="https://www.choicehotels.com/florida/destin/ascend-hotels/flf10?mc=llgoxxpx" target="_blank" rel="noreferrer" className={[classes.ecLink, classes.ecLinkSm].join(' ')}>Book Online</a>
        </td>
      </tr>
    </tbody></table>
  );

  return (
    <div className={classes.PokerRun}>
      <Helmet>
        <title>Lodging Partners for the Emerald Coast Poker Run</title>
        <meta name="description" content="These are the lodging partners for this year's Emerald Coast Poker Run. Book your stay today!" />
      </Helmet>

      <FlyerLay where="HotelsPage" OuterTitle={OuterTitle} InnerTitle={InnerTitle} ContentClass="FlyerTable" FlyerContent={FlyerContent} />
    </div>
  );
}
export default hotels;