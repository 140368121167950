import React from "react";
import { Helmet } from "react-helmet";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import { Link } from "react-router-dom";
import EventSubnav from "../../components/EventSubnav/EventSubnav";
const classes = require('./FAQs.module.scss');

const fAQs = (props) => {
  const OuterTitle=(
    <div>
      <h1>Emerald Coast Poker Run<br/> <span>FAQ's</span></h1>
      <EventSubnav show4="false" addClass="altColor" />
    </div>
  );
  const FlyerContent=(
    <ul data-uk-accordion="collapsible: false">
      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">What is a poker run?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>A poker run is an organized event where participants must visit five to seven checkpoints, drawing a playing card at each one. The object is to have the best poker hand at the end of the run. Having the best hand and winning is purely a matter of chance. The event has a time limit, however the individual participants are not timed.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">What makes the Emerald Coast Poker Run Special?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>The Emerald Coast Poker Run is a marine event for boaters that raises money for youth organizations in Okaloosa County. Stops are open during the day, and the after party is in the evening.</p>
          <p>We have more than 7 check points for participants to visit (if they choose) so they can utilize even more cards to create the best hand possible. Participants must have a visited a minimum of 5 check points to build a proper hand. <a href="/stops" className="text-link">Click here</a> to see our check point locations.</p>
          <p>At the Poker Run Party, which is after the days’ run, participants bring their check point cards in and exchange them for playing cards to build their hand. 10 seats at the final table are awarded, where participants then play poker for the grand prizes. </p>
          <p>The Poker Run party also features food & beverage and entertainment as part of the registration fee, a silent auction, and additional gaming like black jack and roulette.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">What kind of boats can participate?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>The poker run is open to all boats. For many years, the Emerald Coast Poker Run was strictly for power boats. However, due to some shifts in strategic partnerships in recent years, we are currently catering to many more leisure and fishing boats, pontoons, and center consoles.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">How can I participate if I don’t have a boat?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>If you have a friend or family member with a boat, you can register for full benefits and ride with them! Just be sure to indicate that on your registration form.</p>
          <p>If that is not an option, you can purchase individual tickets to join us at the Poker Run party. <a href="/party-tickets" className="text-link">Click here</a> to purchase poker run party tickets.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">Who organizes the Emerald Coast Poker Run?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>The Emerald Coast Foundation is non-profit organization that has raised over 2.5 million dollars for local youth organizations over the last 30 years. We are a 100% volunteer board of directors comprised of community members with diverse professional backgrounds. </p>
          <p>ECF works with community partners throughout the area to sponsor the event, our beneficiary organizations to provide support for the event, and hundreds of volunteers to organize every aspect of the event. From updating this website, to organizing logistics of the stops, and negotiating contracts for food & beverage and entertainment at the event, and even stuffing pre-registration packages with your merchandise and information, our board and volunteers spend thousands of hours throughout the year striving to improve processes and procedures to ensure a safe, organized and fun event.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">What do I get with my registration?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>While registration benefits are subject to change after end of year evaluations, and due to sponsorship commitments changes year over year, ECF has consistently brought participants tremendous value with their registration. <Link to="/registration" className="text-link">Click here</Link> to see your registration options and benefits.</p>
        </div>
      </li>

      <li className={[classes.item].join(' ')}>
        <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#">Who are the beneficiaries of the Emerald Coast Poker Run?</button>
        <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
          <p>
          All Sports Association<br/>
          Arc of the Emerald Coast<br/>
          Children in Crisis<br/>
          Eleanor Johnson Youth Center<br/>
          Fresh Start for Children &amp; Families
          </p>
        </div>
      </li>
    </ul>
  );
  return (
    <div className={classes.FAQs}>
      <Helmet>
        <title>FAQs | Emerald Coast Poker Run</title>
        <meta name="description" content="Read through our FAQs for answers to your questions about the Emerald Coast Poker Run!" />
      </Helmet>
    
      <FlyerLay where="FaqPage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} ContentClass="DropContent" InnerWrap="insideN" />
    </div>
  );
}
export default fAQs;