import React from "react";
import classes from "./Shared.module.scss";

const phone = (props) => {
  return (
    <a className={[classes.callLink, classes[props.where]].join(' ')} href="tel: +18503761037">
      {props.addText ? [props.addText] : null} <br className={classes.cbr}/>
      {props.callText ? [props.callText] : '850.376.1037'}
    </a>
  );
}
export default phone;