import React from "react";
import { Helmet } from "react-helmet";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
// import image from "../../assets/images/sky.jpg";
import ip from "../../assets/images/ip.png";
import logo from "../../assets/images/ecfoundation2.png";
const classes = require('./DrawDown.module.scss');

const registration = (props) => {
  const OuterTitle = (
    <div>
      <h1>The Emerald Coast Foundation <br/><span>Drawdown</span></h1>
    </div>
  );
  const FlyerContent = (
    <div>
      <h3>Live Drawing November 9 </h3>
      <h4>Will You Take Home the <span>$10,000 Grand Prize?</span></h4>
      <h5>Only 500 tickets available! <a href="#ddTickets" data-uk-scroll>Get yours now!</a></h5>
      <img src={logo} alt="Emerald Coast Foundation logo" className={classes.ec} /><img src={ip} alt="IP Casino logo" className={classes.ip} />

      <div className={classes.ddPlax}>
        <div className={classes.divShape}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z"></path>
          </svg>
        </div>

        <div className={classes.il}>
          <p className={classes.first}>Each $100 Ticket Gets You:</p>
          <p>A <span>one-night stay</span> at the IP Casino Resort &amp; Spa in Biloxi, MS</p>
          <p>A chance to win $10,000 cash!</p>
        </div>
        <ul>
          <p>Cash prizes:</p>
          <li><span>1st Ticket</span>: $100</li>
          <li><span>100th Ticket</span>: $200</li>
          <li><span>200th Ticket</span>: $500</li>
          <li><span>300th Ticket</span>: $1500</li>
          <li><span>400th Ticket</span>: $2500</li>
          <li><span>500th Ticket</span>: $10,000</li>
        </ul>
      </div>
      

      <div id="ddTickets" className={classes.formRow}>
        <p className={[classes.formHd, classes.red].join(' ')}>Tickets Are Sold Out!</p>
        {/* <p className={classes.formHd}>Get Your Tickets</p>
        <script type="text/javascript" src="https://form.jotform.com/jsform/212005493284148"></script>
        <iframe
          id="JotFormIFrame-212005493284148"
          title="Emerald Coast Poker Run Registration Form"
          onLoad="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowFullScreen={true}
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/212005493284148"
          frameBorder="0"
          style={{minWidth:'100%',height:'auto',border:'none'}}
          scrolling="no"
        >
        </iframe> */}
      </div>
    </div>
  );
  

  return (
    <div className={classes.Registration}>
      <Helmet>
        <title>Drawdown | Emerald Coast Poker Run</title>
        <meta name="description" content="Read through this page to learn about The Emerald Coast Poker Run Drawdown. Each ticket is a chance to win a $10,000 prize!" />
      </Helmet>
      
      <FlyerLay where="DrawDownPage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} />
      {/* fwRow={fwRow}  */}
    </div>
  );
}
export default registration;