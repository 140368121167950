import React from "react";
import { Helmet } from "react-helmet";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
// import image from "../../assets/images/plunge.jpg";
const classes = require('./Plunge.module.scss');

const plunge = (props) => {
  const OuterTitle = (
    <div>
      <h1>The Emerald Coast Foundation <br/><span>Pelican Plunge</span><br/>January 1 &middot; 10:30am</h1>
      <div className={classes.links}>
        <a href="https://bit.ly/pelican-plunge-2024" target="_blank" rel="noreferrer" className={[classes.ecLinkRev, classes.ecLinkSmX].join(' ')}>Get Tickets</a>
        <a href="#details" className={[classes.ecLink, classes.ecLinkSmX].join(' ')} data-uk-scroll>Details</a>
        <a href="#options" className={[classes.ecLinkRev, classes.ecLinkSmX].join(' ')} data-uk-scroll>Registration Options</a>
      </div>
    </div>
  );
  const FlyerContent = (
    <div>
      <p>Come celebrate 2024 with a plunge into the Gulf of Mexico at The Boardwalk's 7th Annual Pelican Plunge! Whether you dip, dive, flop, or leap into the water, you'll 
      be freezin' for a reason on New Year's Day. 100% of proceeds directly support the children of The Emerald Coast Foundation… IT'S FOR THE KIDS!</p>
      {/* <img src={image} alt="Pelican Plunge" className={classes.fwImg} /> */}

      <div id="details" className={classes.ddPlax}>
        <div className={classes.divShape}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z"></path>
          </svg>
        </div>

        <div className={[classes.il, classes.one].join(' ')}>
          <p className={classes.bmargin0}><b>When: January 1st &middot; 10:30am </b></p>
          <hr/>

          <p className={classes.starter}><b>Starting with lively pre-plunge activities<br/> at The Boardwalk on Okaloosa Island.</b></p>
          <p className={classes.pleft1}>Warm-up with hot cocoa &amp; coffee</p>
          <p className={classes.pleft1}>Secure raffle tickets for a chance to win amazing prizes</p>
          <p className={classes.pleft1}>Partake in the costume contest</p>
          <p className={classes.pleft1}>Enjoy live music before taking an exhilarating dip into the Gulf</p>
        </div>

        <div className={[classes.il, classes.two].join(' ')}>
          <p><b>At 11:30am, all participants will line up near the water for the official countdown to dash into the Gulf of Mexico. </b> </p>
          <p>Directly after, plungers will flock over to The Crab Trap restaurant to get dry and toasty at the post-plunge party. 
          Warm-up with The Crab Trap's famous gumbo, pour on the holiday fun with plunge-worthy drink specials, and mix and mingle to live music. </p>
          <p>The festivities also include a large raffle with thousands of dollars in prizes! All proceeds are raised to help local children in our community.</p>
        </div>
      </div>
    </div>
  );

  const fwRow = (
    <div id="options" className={classes.full}>
      <h4>Registration Options</h4>
      <p>Pre-Register &amp; get your ticket now! <br/>Online registration will remain open until New Year's Eve.</p>
      <a href="https://bit.ly/pelican-plunge-2024" target="_blank" rel="noreferrer" className={[classes.ecLink3, classes.ecLinkSm].join(' ')}>Get Tickets</a>
    </div>
  );

  const FlyerContent2 = (
    <div className={classes.sec2}>
      <div className={[classes.il2, classes.one].join(' ')}>
        <p className={classes.levelName}>PLUNGE TICKET &middot; $24</p>
        <p><b>All access to pre &amp; post plunge activities</b><br/>
        First 100 registrants will receive the 2024 Pelican Plunge long-sleeved shirt with their ticket</p>
    
        <p className={classes.levelName}>CRAB OUT TICKET &middot; $24</p>
        <p><b>All access to pre &amp; post plunge activities</b><br/> Without the plunge</p>
    
        <p className={classes.levelName}>DAY-OF TICKETS &middot; $30</p>
        <p><b>All access to pre &amp; post plunge activities</b><br/> Without the early savings</p>
      </div>

      <div className={[classes.il2, classes.two].join(' ')}>
        <p className={classes.levelName}>KING CRAB VIP TICKET &middot; $400</p>
        <p><b>New exclusive ticket <br/> Available starting 12/10</b><br/>
        Only 4 available &middot; 600 Value! <br/> Elevate your New Year's, and be King of the Plunge! Crab yours before they are gone!</p>
        <div className={classes.vip}>
          <p className={classes.bmargin0}><b>The exclusive KING CRAB VIP TICKET includes:</b></p>
          <p>New Year's Eve night stay at The Island Hotel <br/>on Okaloosa Island, with late check out at 1pm<br/><span>Check-in 12/31/22 &middot; Check-out 1/1/23</span></p>
          <p>2 Rockin New Year's Eve gift certificates to Rockin Tacos</p>
          <p>2 Pelican Plunge tickets &middot; including 2 long-sleeved shirts</p>
          <p>“King Crab VIP” Crab Trap gift certificate</p>
          <p>New Year Spa Basket from <br/>Hotel Effie at Sandestin Golf &amp; Beach Resort®</p>
        </div>
      </div>
    </div>
  );
  

  return (
    <div className={classes.Plunge}>
      <Helmet>
        <title>Annual Pelican Plunge | Emerald Coast Poker Run</title>
        <meta name="description" content="Read through this page to learn more about The Emerald Coast Foundation's Pelican Plunge!" />
      </Helmet>
      
      <FlyerLay where="PlungePage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} fwRow={fwRow} FlyerContent2={FlyerContent2} />
      {/* fwRow={fwRow}  */}
    </div>
  );
}
export default plunge;