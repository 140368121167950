import React from "react";
import { Link } from "react-router-dom";
import classes from './Header.module.scss';
import packet from "../../SponsorshipPackage.pdf";
const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

const navItems = (props) => {
  return (
    <ul className={[classes[props.navClass], props.addClass].join(' ')}>
      <li className={[classes.navItem, classes.homeLink].join(' ')}><Link to="/">Home</Link></li>
      <li className={classes.navItem}><Link to="/ec-foundation">The Foundation</Link></li>
      {/* <li className={[classes.navItem, classes.draw, classes.attn].join(' ')}><Link to="/drawdown">Drawdown</Link></li> */}
      <li className={[classes.navItem, classes.attnXX].join(' ')}><Link to="/pelican-plunge">Pelican Plunge</Link></li>
      <li className={[classes.navItem, classes.mob].join(' ')}><Link to="/contact">Contact Us</Link></li>
      <li className={[classes.navItem, classes.withDrop].join(' ')}><Link to="#">Poker Run {dropIcon}</Link>
        <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')}>
          <ul className="uk-nav uk-navbar-dropdown-nav">
            <li className={[classes.dropLi, classes.sig].join(' ')}><Link to="/register-now">Register Now</Link></li>
            <li className={classes.dropLi}><Link to="/poker-run">Event Details</Link></li>
            <li className={classes.dropLi}><Link to="/registration">Registration Options</Link></li>
            <li className={classes.dropLi}><Link to="/stops">Stops</Link></li>
            <li className={classes.dropLi}><Link to="/trailer-parking">Boat Trailer Parking</Link></li>
            <li className={classes.dropLi}><Link to="/faqs">FAQs</Link></li>
            <li className={classes.dropLi}><Link to="/party-tickets">Party Tickets</Link></li>
            <li className={classes.dropLi}><a href="https://ecpoker2024.ggo.bid/bidding/package-browse" target="_blank" rel="noreferrer">Silent Auction</a></li>
          </ul>
        </div>
      </li>
      
      <li className={[classes.navItem, classes.hotels].join(' ')}><Link to="/lodging-partners">Accommodations</Link></li>

      <li className={[classes.navItem, classes.withDrop].join(' ')}><Link to="#">Sponsors {dropIcon}</Link>
        <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')}>
          <ul className="uk-nav uk-navbar-dropdown-nav">
            <li className={classes.dropLi}><Link to="/sponsors">Our Sponsors</Link></li>
            <li className={classes.dropLi}><a href={packet} target="_blank" rel="noreferrer">Become a Sponsor</a></li>
          </ul>
        </div>
      </li>
      {/* <li className={classes.navItem}><Link to="/gallery">Gallery</Link></li> */}
      <li className={[classes.navItem, classes.fw].join(' ')}><Link to="/contact">Contact Us</Link></li>
    </ul>
  );
}
export default navItems;