import React from "react"
import classes from "./Shared.module.scss"

export const Message = (props) => {  
  return (
    <div className={[classes.msgBanner, classes[props.addClass ?? 'std'], classes[props.addSpec ?? 'gen']].join(' ')}>
      <div className={classes.medDef}>
        <h4>** ATTENTION **</h4>
        <p>
          Registration and pre-registered packages can <b>ONLY BE PICKED UP AT <br className={classes.one} /><span className={classes.und}>THE ISLAND RESORT </span>
          ON <span className={classes.und}>FRIDAY AUGUST 9th,</span> FROM <span className={classes.und}>2-7pm</span>!</b>
          </p><p className={classes.em}>
          <span className={classes.und}>No</span> packages or registration will be at the Captain's Party. <br className={classes.two} />
          <span className={classes.und}>No</span> extra party tickets can be purchased at either party!
        </p>
      </div>
    </div>
  )
}