import React, { Component } from "react";
const classes = require('./SponsorLoop.module.scss');

class SponsorLoop extends Component {
  render() {
    const partnerLoop = this.props.partners.map((partner) =>
      <li key={partner.id} className={[classes.partner, classes[partner.addClass]].join(' ')}>
        {partner.link ? <a href={partner.link} target="_blank" rel="noreferrer" className={classes.partnerLink}><img src={partner.img} alt={[partner.name]} /></a> : <img src={partner.img} alt={[partner.name]} />}
      </li> 
    );
    return (
      <div className={[classes.SponsorLoop, classes[this.props.tier], classes[this.props.bgClass]].join(' ')}>{/* , classes[this.props.border] */}
        <ul className={[classes.slItems, classes[this.props.wrapClass]].join(' ')}>
          {partnerLoop}
        </ul>
      </div>
    );
  }
}
SponsorLoop.defaultProps = {
  bgClass: "default",
  wrapClass: "slFlow"
};
export default SponsorLoop;