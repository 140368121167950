import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import classes from './Gallery.module.scss';
import FlyerLay from "../../components/FlyerLay/FlyerLay";
const user = "145100414@N03";
const api = "82a0808081a2848546d899c52d50e789";

class Gallery extends Component {
  constructor(){
    super();
    this.state = {
      pictures: [],
    };
  }

  componentDidMount(){
    // testing
    fetch('https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=' + api + '&user_id=' + user + '&album_id=72157698342717621-30189654288&per_page=12&page=1&format=json&nojsoncallback=1')
    // fetch('https://api.flickr.com/services/rest/?method=flickr.galleries.getPhotos&api_key=' + api + '&gallery_id=66911286-72157647277042064&format=json&nojsoncallback=1')
    .then(function(response){
      return response.json();
    })
    .then(function(j){
      let picArray = j.photos.photo.map((pic) => {
        var srcPath = 'https://farm'+pic.farm+'.staticflickr.com/'+pic.server+'/'+pic.id+'_'+pic.secret+'.jpg';
        return(
          <a href={srcPath} className={classes.galleryImg}><img alt="Emerald Coast Poker Run 2018" src={srcPath} /></a>
        )
      })
      this.setState({pictures: picArray});
    }.bind(this))
  }

  render() {
    const OuterTitle = (
      <div>
        <h1>2020 Poker Run <br/><span>Gallery</span></h1>
      </div>
    );
    const InnerTitle = (
      <div>
        <p className={classes.bmargin1}>Take a look at all the fun we had at last year's Poker Run. Follow the link below to see more photos.</p>
        <a href="https://www.flickr.com/photos/145100414@N03/albums/" target="_blank" rel="noreferrer" className={classes.ecLinkSm}>More Photos</a>
        <div className={classes.clearfixBtn}/>
      </div>
    );
    const FlyerContent = (
      <div className={classes.imageGallery} data-uk-lightbox>
        {this.state.pictures}
      </div>
    );

    return (
      <div className={classes.Gallery}>
        <Helmet>
         <title>Photo Gallery | Emerald Coast Poker Run</title>
         <meta name="description" content="Take a look at all the fun we've had over the years, and get ready for the upcoming Poker Run!" />
       </Helmet>
      
        <FlyerLay where="GalleryPage" OuterTitle={OuterTitle} InnerTitle={InnerTitle} xContentClass="FlyerTable" FlyerContent={FlyerContent} Wrap="insideAlt" InnerWrap="grid" />
      
        {/* <a data-flickr-embed="true" href={"https://www.flickr.com/photos/" + user + "/albums/72157698342717621"} title="2018 26th Annual Emerald Coast Boat Week and Poker Run">
            <img src="https://live.staticflickr.com/1792/30189823278_9bcd4d2a95_z.jpg"   alt="2018 26th Annual Emerald Coast Boat Week and Poker Run"/>
          </a> */}
      </div>
    );
  }
}
export default Gallery;