import React from "react"
import { Helmet } from "react-helmet"
import classes from "./Sponsors.module.scss"
import FlyerLay from "../../components/FlyerLay/FlyerLay"
import SponsorLoop from "../../components/SponsorLoop/SponsorLoop"
import ipCasino from "../../assets/images/sponsors/ipCasino.png"
import meBingo from "../../assets/images/sponsors/meBingo.jpg"
import block from "../../assets/images/sponsors/block.png"
import ajsBayou from "../../assets/images/sponsors/ajsBayou.png"
import hsdh from "../../assets/images/sponsors/hsdh.png"
import legMarina from "../../assets/images/sponsors/legMarina.png"
import advMarina from "../../assets/images/sponsors/advMarina.jpg"
import tsc from "../../assets/images/sponsors/tsc.jpg"
import thrive from "../../assets/images/sponsors/thrive.jpg"
import destinHarbor from "../../assets/images/sponsors/destinHarbor.png"
import gatlins from "../../assets/images/sponsors/gatlins.jpg"
import cumulus from "../../assets/images/sponsors/cumulus.png"
import georges from "../../assets/images/sponsors/georges.png"
import marineMax from "../../assets/images/sponsors/marineMax.png"
import theIsland from "../../assets/images/sponsors/islandResort.png"
import erskine from "../../assets/images/sponsors/aErskine.png"
import harley from "../../assets/images/sponsors/harley.jpg"
import ricks from "../../assets/images/sponsors/ricks.png"
import beachWeekend from "../../assets/images/sponsors/beachWeekend.png"
import sandestin from "../../assets/images/sponsors/sandestin.png"
import united from "../../assets/images/sponsors/united.png"
import woolpert from "../../assets/images/sponsors/woolpert.jpg"
import gus from "../../assets/images/sponsors/bigGus.png"
import vibe from "../../assets/images/sponsors/vibe.png"
import midSouth from "../../assets/images/sponsors/midsouth.jpg"
import fuds from "../../assets/images/sponsors/fuds.png"
import liveOak from "../../assets/images/sponsors/liveOak.png"

// , addClass: 'long'
const tier1 = [{id: 1, name: "IP Casino", link: "http://www.ipbiloxi.com/", img: [ipCasino]}];
const tier2 = [
  {id: 0, name: "Fudpuckers", link: "https://www.fudpucker.com/", img: [fuds]},
  {id: 0, name: "Live Oak Fiber", link: "https://liveoakfiber.com/", img: [liveOak]},
  {id: 1, name: "AJ's On The Bayou", link: "https://www.facebook.com/AJsOnTheBayou/ ", img: [ajsBayou]},
  {id: 2, name: "The Block", link: "https://theblockclub.com/", img: [block]},
  {id: 4, name: "High Seas Design House", link: "http://hsdesignhouse.com", img: [hsdh]},
  {id: 5, name: "Legendary Marina", link: "http://www.legendarymarina.com", img: [legMarina]},
  {id: 6, name: "Mary Esther Bingo", link: "http://www.maryestherbingo.com/", img: [meBingo]},
  {id: 28, name: "Beach Weekend", link: "https://beachweekend.com/", img: [beachWeekend]},
];
const tier3 = [
  {id: 10, name: "Adventure Marina", link: "http://www.adventuremarina.com/", img: [advMarina]},
  {id: 15, name: "The Inn at Destin Harbor", link: "https://www.choicehotels.com/florida/destin/ascend-hotels/flf10?mc=llgoxxpx", img: [destinHarbor]},
  {id: 12, name: "Thrive Wellness", link: "https://www.thrivefwb.com/", img: [thrive]},
  {id: 40, name: "TSC Productions", link: "http://s572166334.onlinehome.us/", img: [tsc]},
  {id: 16, name: "Marine Max", link: "https://www.marinemax.com/", img: [marineMax]},
  {id: 17, name: "The Island Resort", link: "https://be.synxis.com/?adult=1&arrive=2024-08-08&chain=6255&child=0&currency=USD&depart=2024-08-11&group=2408ECPOKR&hotel=37398&level=hotel&locale=en-US&rooms=1", img: [theIsland]},
  {id: 29, name: "Sandestin Golf and Beach Resort", link: "https://www.sandestin.com/", img: [sandestin]},
  {id: 30, name: "United Fidelity Bank", link: "https://www.unitedfidelity.com/", img: [united]},
  {id: 27, name: "Rick's on The Island", link: "https://ricksontheisland.com/", img: [ricks]},
  {id: 11, name: "Emerald Coast Harley-Davidson", link: "https://www.emeraldcoasthd.com/", img: [harley]},  
  {id: 13, name: "Big Gus Tractor & Equipment", link: "https://www.biggustractor.com/", img: [gus]},
  {id: 14, name: "Emerald Coast Vibe", link: "https://www.emeraldcoastvibe.com/", img: [vibe]},
  {id: 14, name: "MidSouth Bank", link: "https://www.bankmidsouth.com/", img: [midSouth]},
  
];
const tier4 = [
  {id: 22, name: "Cumulus Media", link: "https://www.cumulusmedia.com/", img: [cumulus]},
  {id: 20, name: "Gatlin's Cleaning Service", img: [gatlins]},
  {id: 24, name: "Two Georges Marina", link: "http://twogeorgesmarina.com/", img: [georges]},
  {id: 26, name: "Aaron Erskine", link: "", img: [erskine]},
  {id: 26, name: "Woolpert", link: "https://woolpert.com/", img: [woolpert]},
];

const sponsors = () => {
  const OuterTitle=(<h1>Emerald Coast Poker Run <br/><span>Partners</span></h1>);

  const InnerTitle=(
    <div className={classes.med3Def}>
      <p>The Emerald Coast Poker Run would not be possible without some amazing individuals, volunteers, and businesses. The 
        Emerald Coast Foundation would like to give a special thanks to all of our generous sponsors and lodging partners for helping to make these events happen.</p>
    </div>
  );
  const FlyerContent=(
    <div>
      <SponsorLoop partners={tier1} tier="tier1" />
      <SponsorLoop partners={tier2} tier="tier2" bgClass="alt" />
      <SponsorLoop partners={tier3} tier="tier3" />
      <SponsorLoop partners={tier4} tier="tier4" bgClass="alt" />
    </div>
  );

  return (
    <div className={classes.Sponsors}>
      <Helmet>
        <title>Emerald Coast Poker Run Sponsors</title>
        <meta name="description" content="Boat week and the Emerald Coast Poker Run would not be possible without our amazing sponsors. The Emerald Coast Foundation would like to give a special thanks to all of them!" />
      </Helmet>
    
      <FlyerLay where="SponsorsPage" OuterTitle={OuterTitle} InnerTitle={InnerTitle} fwRow={FlyerContent} addClass="external" />
    </div>
  );
}
export default sponsors;