import React, { Component } from "react";
import { Link } from "react-router-dom";
const classes = require('./EventSubnav.module.scss');

class EventSubnav extends Component {
  render() {
    const subItems = [
      {id: 1, name: "Register Now", link: "/register-now", handle: "regNow", show: this.props.show1},
      {id: 2, name: "Registration Options", link: "/registration", handle: "regOpts", show: this.props.show2},
      {id: 3, name: "Event Details", link: "/poker-run", handle: "dets", show: this.props.show3},
      {id: 4, name: "FAQ's", link: "/faqs", handle: "faqs", show: this.props.show4},
      {id: 5, name: "Stops", link: "/stops", handle: "stops", show: this.props.show5},
      {id: 6, name: "Boat Trailer Parking", handle: "parking", link: "/trailer-parking", show: this.props.show6},
      {id: 7, name: "Purchase Party Tickets", handle: "tix", link: "/party-tickets", show: this.props.show7},
    ];

    let n = 0;
    const subnavLoop = subItems.map(item => {
      return item.show === "true" ?
        <li key={item.id} className={[classes.item, classes["item" + [n+=1]], classes[item.handle]].join(' ')}>
          <Link to={item.link} className={[classes.snLink].join(' ')}>{item.name}</Link>
        </li>
      :
        null
    });
    return (
      <div className={[classes.EventSubnav, classes["sl" + n]].join(' ')}>
        <div className={[classes.esWrap, classes[this.props.wrap], classes[this.props.addClass]].join(' ')}>
          <ul className={classes.subloop}>
            {subnavLoop}
          </ul>
        </div>
      </div>
    );
  }
}
EventSubnav.defaultProps = {
  // wrap: "medDef",
  show1: "true",
  show2: "true",
  show3: "true",
  show4: "true",
  show5: "false",
  show6: "false",
  show7: "false",
}
export default EventSubnav;