import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/emeraldcoastpokerrun/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Emerald Coast Foundation Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://www.instagram.com/ec_poker_run/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Emerald Coast Foundation Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>

      <li><a href="https://twitter.com/runpoker" target="_blank" rel="noopener noreferrer">
        <span aria-label="Emerald Coast Foundation Twitter Link" uk-icon="icon: twitter"></span>
      </a></li>

      <li><a href="https://www.youtube.com/channel/UCywMc0ugsQ847hYdkMi-ytA" target="_blank" rel="noopener noreferrer">
        <span aria-label="Emerald Coast Foundation YouTube Link" uk-icon="icon: youtube"></span>
      </a></li>

      <li><a href="https://www.flickr.com/photos/145100414@N03/albums" target="_blank" rel="noopener noreferrer">
        <span aria-label="Emerald Coast Foundation Flickr Link" uk-icon="icon: flickr"></span>
      </a></li>
    </ul>
  );
};

export default social;
