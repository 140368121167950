import React from "react"
import { Helmet } from "react-helmet"
import classes from "./PartyTickets.module.scss"
import FlyerLay from "../../components/FlyerLay/FlyerLay"
import EventSubnav from "../../components/EventSubnav/EventSubnav"
// import PHtext from "../../components/Shared/PhEventText"
// import phImage from "../../assets/images/speedboat.jpg"

const partyTickets = (props) => {
  const dateText = (<>{props.dates} ·</>)

  const OuterTitle = (<div>
    <h1>Emerald Coast Poker Run<br/>
      <span>Party Tickets</span>
    </h1>
    <EventSubnav addClass="altColor" />
  </div>)

  const InnerTitle = (
    <div>
      <p><b>When:</b><br/> {props.dates ? dateText : null} 6pm</p>
      <p><b>Where:</b><br/> The Block · Fort Walton Beach</p>
      <p><b>Who:</b><br/> *Must be 21 to attend</p>
      <p><b>Cost:</b><br/> $95 per ticket</p>
      {/* <p>Poker Run Party: {props.dates ? dateText : null} 6pm · The Block Fort Walton Beach
        <br/>
         *Must be 21 to attend
        <br/>
        <b>Cost:</b> $95 per ticket
      </p> */}
    </div>
  )
  const FlyerContent = (
    <div>
      <script type="text/javascript" src="https://form.jotform.com/jsform/241304359727155"></script> 
      <iframe
        id="JotFormIFrame-241304359727155"
        title="Emerald Coast Poker Run Party Tickets Form"
        onLoad={window.parent.scrollTo(0,0)}
        allowFullScreen={true}
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/241304359727155"
        style={{minWidth:'100%',height:'auto',border:'none'}}
      ></iframe>
    </div>
  )

  // const fwRow = (
  //   <>
  //     <PHtext where="PartyTixPage" /><div className={classes.clearfix} /><br/>
  //     <img src={phImage} alt="Emerald Coast Poker Run" />
  //   </>
  // )

  return (
    <div className={classes.PartyTickets}>
      <Helmet>
        <title>Additional Party Tickets | Emerald Coast Poker Run</title>
        <meta name="description" content="Purchase additional tickets for the annual Emerald Coast Poker Run Party, and enjoy a night of cocktails, snacks, music, and other entertainment!" />
      </Helmet>

      <FlyerLay where="PartyTixPage" OuterTitle={OuterTitle} InnerTitle={InnerTitle} FlyerContent={FlyerContent} margin="15px 0 0" />
      {/* <FlyerLay where="PartyTixPage" OuterTitle={OuterTitle} fwRow={fwRow} margin="-10px auto 0" /> */}
    </div>
  );
}
export default partyTickets;