import React from "react";
import { Link } from "react-router-dom";
// import { FacebookProvider, Page } from 'react-facebook';
import Social from "../Social/Social";
// import Phone from "../Shared/Phone";
// import Email from "../Shared/Email";
// import Drawer from "../Header/Drawer";
import prLogo from "../../assets/images/pokerRunLogo.png";
import ecLogo from "../../assets/images/ecfoundation1.png";
const classes = require('./Footer.module.scss');

const footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.med3Def}>        
        <div className={[classes.ftCol, classes.fc2].join(' ')}>
          <Social where="footer" />
          <p className={[classes.copy, classes.left].join(' ')}>© {(new Date().getFullYear())} Emerald Coast Foundation. <br/>
          501(c)(3) Charity: EIN 55-0796930<br/>
          Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>

        <div className={[classes.ftCol, classes.fc3].join(' ')}>
          <Link to="/"><img src={prLogo} alt="Emerald Coast Poker Run Logo" className={[classes.logo1].join(' ')} /></Link>
          <Link to="/ec-foundation"><img src={ecLogo} alt="Emerald Coast Foundation Logo" className={[classes.logo2].join(' ')} /></Link>
        </div>

        {/* <div className={[classes.ftCol, classes.fc1].join(' ')}>
          <FacebookProvider appId="1091531091253937">
            <Page href="https://www.facebook.com/EmeraldCoastFoundation/" tabs="timeline" height="80" />
          </FacebookProvider>    
        </div> */}
      </div>
    </footer>
  );
}

export default footer;





