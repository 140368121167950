import React, {Component} from 'react'
import { Route, Switch } from "react-router-dom"
import { TinyButton as ScrollUpButton } from "react-scroll-up-button"
import classes from './App.module.scss'
import Phone from "./components/Shared/Phone"
import Email from "./components/Shared/Email"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Modal from "./components/Modal/Modal"
import Home from './hoc/Home/Home'
import About from './hoc/About/About'
import PokerRun from './hoc/PokerRun/PokerRun'
import Stops from './hoc/Stops/Stops'
import BoatTrailer from "./hoc/BoatTrailer/BoatTrailer"
import Contact from "./hoc/Contact/Contact"
import Gallery from "./hoc/Gallery/Gallery"
import PartyTickets from "./hoc/PartyTickets/PartyTickets"
import RegisterNow from "./hoc/RegisterNow/RegisterNow"
import Registration from "./hoc/Registration/Registration"
import Sponsors from "./hoc/Sponsors/Sponsors"
import Hotels from "./hoc/Hotels/Hotels"
import FAQs from "./hoc/FAQs/FAQs"
import Foundation from "./hoc/Foundation/Foundation"
import DrawDown from "./hoc/DrawDown/DrawDown"
import Fallback from './hoc/Fallback/Fallback'
import Plunge from './hoc/Plunge/Plunge'
import { Message } from "./components/Shared/Announcement"


// KPKP check hotels page for coded dates
const year = "2024"
const dates = "August 9-10, 2024"
const start = "August 9"
const startB = "8/9"
const end = "August 10"
const endB = "8/10"
const closeDate = "TBD"
const modalDonate = (
  <div className={[classes.textCenter].join(' ')}>
    <div className={[classes.XinsideN, classes.custIN].join(' ')}>
      <p className={classes.bmargin0}>In the past 24 years, the Emerald Coast Foundation has exceeded <span>$2,000,000</span> in donations, in support of various local youth orientated organizations. Your 
      dollars have helped keep the doors open for facilities that help local kids in need. <span>What better way to invest in your own community than "investing in youth?"</span></p>
    </div>
    <div className={classes.clearfix}/><hr/><hr/><div className={classes.clearfix}/>

    <div className={[classes.fullRow, classes.mobile0].join(' ')}>
      <div className={[classes.XinsideAlt, classes.custIA].join(' ')}>
        <div className={[classes.hlf1, classes.equal].join(' ')}>
          <p className={classes.h4}><b>Donate Through Paypal</b></p>
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
            <input type="hidden" name="cmd" value="_s-xclick"/>
            <div className={classes.clearfix}/>
            <input type="hidden" name="hosted_button_id" value="N3BMNHW44TFUC"/>
            <div className={classes.clearfix}/>
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
            <br/>
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
          </form>
        </div>

        <div className={[classes.hlf2, classes.equal].join(' ')}>
          <p className={classes.h4}><b>Contact Information</b></p>
          <p><Phone where="dmodal" /></p>
          <p>P.O. Box 1208<br/> Fort Walton Beach, Fl 32549 </p>
          <p><Email where="dmodal" /></p>
        </div>
      </div>
    </div>
  </div>
);

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact={true} path="/" render={(props) => <Home {...props} dates={dates} />} />
        <Route path="/about" component={About} />
        <Route path="/poker-run" render={(props) => <PokerRun {...props} start={start} end={end} /> } />
        <Route path="/pelican-plunge" render={(props) => <Plunge {...props} />} />
        <Route path="/stops" render={(props) => <Stops {...props} dates={dates} />} />
        <Route path="/trailer-parking" render={(props) => <BoatTrailer {...props} dates={dates} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route path="/gallery" render={(props) => <Gallery {...props} />} />
        <Route path="/party-tickets" render={(props) => <PartyTickets {...props} dates={end} />} />
        <Route path="/register-now" render={(props) => <RegisterNow {...props} dates={dates} />} />
        <Route path="/registration" render={(props) => <Registration {...props} dates={dates} />} />
        <Route path="/sponsors" render={(props) => <Sponsors {...props} />} />
        <Route path="/faqs" render={(props) => <FAQs {...props} />} />
        <Route path="/drawdown" render={(props) => <DrawDown {...props} />} />
        <Route path="/draw-down" render={(props) => <DrawDown {...props} />} />
        <Route path="/ec-foundation" render={(props) => <Foundation {...props} />} />
        <Route path="/lodging-partners" render={(props) => <Hotels {...props} start={startB} end={endB} year={year} close={closeDate} />} />
        <Route component={Fallback} />
      </Switch>
    );

    const pathName = window.location.pathname.replace('-','')
    const wrapClass = pathName.replace('/', '')
    let headClass="mainHeader"
    if (window.location.pathname === '/') {
      headClass="homeHeader"
    }

    return (
      <div className={[classes.ecfWrap, classes[wrapClass]].join(' ')}>
        <Header HeaderType={headClass} />
        <Message addClass="hide940" />
        <main className={classes.MainContent}>
          {routes}
        </main>
        <Footer />

        <Modal id="modalDonate" modalClass="donateModal" wrap="med4Def" modalTitle="Donate to the Emerald Coast Foundation" modalContent={modalDonate} />
        
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#000', padding: '0px 5px', bottom: '15px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
