import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import NavItems from "./NavItems";
// import { Link } from "react-router-dom";
const classes = require('./Header.module.scss');
const styles = require('../Footer/Footer.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <NavItems navClass="SideNav" />
    </div>
  );

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <Button onClick={toggleDrawer('left', true)}>
        <div className={[classes.mobileNavBtn, styles.mobileNavBtn].join(' ')}>
          <span className={styles.noFt} />
          <span className={styles.noFt} />
          <span className={styles.noFt} />
        </div>
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;