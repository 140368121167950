import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import SkewBg from "../../components/SkewBg/SkewBg";
// import png from "../../assets/images/fleetSolo2.png";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import EventSubnav from "../../components/EventSubnav/EventSubnav";
const classes = require('./Fallback.module.scss');

const fallback = () => {
  const OuterTitle = (<h1>Looks like you took a wrong turn.<br/> Try one of the links below to get back on track.</h1>);
  const FlyerContent = (<EventSubnav show5="true" show6="true" show7="true" wrap="med4Def" />);

  return (
    <div className={classes.FallbackPage}>
      <Helmet>
        <title>Emerald Coast Poker Run</title>
        <meta name="description" content="" />
      </Helmet>
             
      <FlyerLay where="FallbackPage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} />
    </div>
  );
}
export default fallback;