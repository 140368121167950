import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Registration.module.scss"
import FlyerLay from "../../components/FlyerLay/FlyerLay"
import EventSubnav from "../../components/EventSubnav/EventSubnav"

const registration = (props) => {
  const dateText = (<>{props.dates}<br/></>);
  
  const OuterTitle = (
    <div>
      <h1>Emerald Coast Poker Run<br/> {props.dates ? dateText : null} <span>Registration Options</span></h1>
      <EventSubnav addClass="altColor" show2="false" show5="true" show6="true" show7="true" />
      <div className={classes.note}><p>*Registrants must be <span>21 or older</span> to participate</p></div>
    </div>
  );
  const FlyerContent = (
    <table>
      <tbody>
        <tr>
          <td>
            <p className={classes.levelName}>
              <span className={classes.vipNote}>ALL ENTRIES ARE NOW VIP</span><br />
              VIP &middot; $349</p>
            <ul>
              <li><b>2 Poker Hands</b><br/> To Play for the Grand Prize</li>
              <li><b>Poker Run Party:</b><br/> Entry for 2 <br/> Preferred VIP Area Including: Buffet &amp; Open Bar</li>
              <li><b>Captain's Party:</b><br/> Entry for 2 Including: Buffet &amp; Open Bar</li>
              <li><b>2 Event T-shirts</b></li>
              <li><b>Weekend Stay at the IP Casino</b><br/> Biloxi MS</li>
              <li className={classes.age}>*Registrants must be 21 or older</li>
              <li>$750 Value</li>
            </ul>
          </td>

          <td className={classes.embass}>
            <p className={classes.levelName}>ECF Ambassador &middot; $449</p>
            <ul>
              <li><b>2 Poker Hands</b><br/> To Play for the Grand Prize</li>
              <li><b>Poker Run Party:</b><br/> Entry for 2 <br/> Preferred VIP Area Including: Buffet &amp; Open Bar</li>
              <li><b>Captain's Party:</b><br/> Entry for 2 Including: Buffet &amp; Open Bar</li>
              <li><b>2 Event T-shirts</b></li>
              <li><b>Weekend Stay (2-nights) at the IP Casino</b><br/> Biloxi MS</li>
              <li><b>1 Ticket to the Emerald Coast Drawdown</b><br/>Your 1 in 500 Chance to Win $10,000!</li>
              <li className={classes.nosig}><b>2 Entries to the 2024<br/> New Year's Day Pelican Plunge</b></li>
              <li className={classes.age}>*Registrants must be 21 or older</li>
            </ul>
          </td>
          
          <td className={classes.tix}>
            <p className={classes.levelName}>Party Tickets Only</p>
            <ul>
              <li className={classes.width}>Can't participate in the boat run this year? Join us for the party!</li>
              <li><b>$95 Per Ticket</b></li>
              <li className={classes.age}>*Must be 21 or older to purchase/attend</li>
              <div className={classes.clearfix} />
              <li className={classes.linkLi}><Link to="/party-tickets" className={classes.ecLinkSm}>Get Tickets</Link></li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className={classes.Registration}>
      <Helmet>
        <title>Registration Options | Emerald Coast Poker Run</title>
        <meta name="description" content="The Emerald Coast Poker Run is right around the corner! Check our registration options, and sign up today!" />
      </Helmet>
      
      <FlyerLay where="RegistrationPage" OuterTitle={OuterTitle} ContentClass="FlyerTable" FlyerContent={FlyerContent} />
    </div>
  );
}
export default registration;