import React, {Component} from 'react';
import { Link } from "react-router-dom";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Social from "../Social/Social";
import logo from "../../assets/images/pokerRunLogo.png";
const classes = require('./Header.module.scss');
// const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes[this.props.HeaderType]].join(' ')}>
        <div className={classes.topBar}>
          <div className={classes.socialLinks}>
            <Social where="topbar" />
            <span className={classes.line} />
            <button type="button" data-uk-toggle="target: #modalDonate" className={classes.modalLink}>Donate to the Cause</button>
          </div>
        </div>

        <div className={[classes.insideN, classes.hdWrap].join(' ')}>          
          <nav className={[classes.navMm, "uk-navbar-container"].join(' ')} data-uk-navbar>
            <div className={[classes.logoBlk].join(' ')}><Link to="/" className={[classes.logoMain].join(' ')}><img src={logo} alt="Emerald Coast Poker Run Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link></div>
            <NavItems navClass="navBarCustom" addClass="uk-navbar-nav" />
            <div className={classes.mobNav}><Drawer /></div>
          </nav>        
        </div>
      </header>
    );
  }
}

export default Header;


