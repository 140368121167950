import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import EventSubnav from "../../components/EventSubnav/EventSubnav";
const classes = require('./BoatTrailer.module.scss');

const boatTrailer = (props) => {
  const OuterTitle = (
    <div>
      <p>Emerald Coast Poker Run<br /> {props.dates}</p>
      <h1>Boat Trailer Parking <br /><span>First Come, First Served</span></h1>
      <EventSubnav addClass="altColor" /><div className={classes.clearfix} />
      <h4>Call to make your arrangements for boat trailer parking if needed.</h4>
    </div>
  );
  const FlyerContent = (
    <table><tbody>
      <tr>
        <td>
          <h4>Adventure Marina</h4>
          <hr className={[classes.l120, classes.center].join(' ')} />
          <p>1201 B Miracle Strip Pkway SE<br/> Fort Walton Beach, FL 32548</p>
          <p><span>Melanie Gilbert:<br/><a href="tel:+18505812628">850.581.2628</a></span></p>
          <a href="https://www.adventuremarina.com/" target="_blank" rel="noreferrer" className={[classes.ecLink3, classes.ecLinkSm].join(' ')}>Visit Website</a>
        </td> 

        <td>
          <h4>Two Georges Marina</h4>
          <hr className={[classes.l120, classes.center].join(' ')} />
          <p>100 Old Ferry Road<br/> Shalimar, FL 32579 </p>
          <p><span><a href="tel:+18506510510">George Fussell: <br/>850.651.0510</a></span></p>
          <a href="http://twogeorgesmarina.com/" target="_blank" rel="noreferrer" className={[classes.ecLink3, classes.ecLinkSm].join(' ')}>Visit Website</a>
        </td>

        <td>
          <h4>Legendary Marina, Destin</h4>
          <hr className={[classes.l120, classes.center].join(' ')} />
          <p>4601 Legendary Marina Drive<br/> Destin, FL 32541</p>
          <p><span><a href="tel:+18503378200">Ray Allenbrand: <br/>850.337.8200</a></span></p>
          <a href="http://legendarymarina.com/" target="_blank" rel="noreferrer" className={[classes.ecLink3, classes.ecLinkSm].join(' ')}>Visit Website</a>
        </td>
        
      </tr>
    </tbody></table>
  );

  return (
    <div className={classes.BoatTrailer}>
      <Helmet>
        <title>Boat Trailer Parking for the Emerald Coast Poker Run</title>
        <meta name="description" content="The Emerald Coast Poker Run volunteers want to make everything run as smoothly as possible, so you can focus on having fun! Here are some options for parking boat trailers." />
      </Helmet>
  
      {/* <FlyerLay where="TrailerPage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} ContentClass="FlyerContent2" /> */}
      <FlyerLay where="TrailerPage" OuterTitle={OuterTitle} ContentClass="FlyerTable" FlyerContent={FlyerContent} />

    </div>
  );
}
export default boatTrailer;