import React from "react";
import { Helmet } from "react-helmet";
import classes from './Stops.module.scss';
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import EventSubnav from "../../components/EventSubnav/EventSubnav";
// import MapShow from "../../components/MapShow/MapShow";
import NewMapImg from "../../assets/images/PokerRunMap.png";
import NewMap from "../../PokerRunMap.pdf";

const title = "Emerald Coast Poker Run Stops";
const stops = (props) => {
  const InnerTitle = (
    <div>
      <h1>{title}</h1>
      <h2>{props.dates}</h2>
      <EventSubnav />
      {/* <MapShow /> */}

      <div className={classes.clearfix}/>
      <h4><span>Click the image below to view/download larger version.</span></h4>
      <a href={NewMap} target="_blank" rel="noreferrer"><img src={NewMapImg} alt="Emerald Coast Poker Run Stops"/></a>
      <div className={classes.clearfix}/>
    </div>
  );
  
  return (
    <div className={classes.Stops}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="The Emerald Coast Poker Run is our favorite event of the season. Check out all the stops on this year's list!" />
      </Helmet>

      <FlyerLay where="StopsPage2" InnerTitle={InnerTitle} ContentClass="stops" />
      {/* <FlyerLay where="StopsPage" InnerTitle={InnerTitle} ContentClass="stops" /> */}
    </div>
  );
}
export default stops;