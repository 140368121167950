import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./PokerRun.module.scss";
import FlyerLay from "../../components/FlyerLay/FlyerLay";
import EventSubnav from "../../components/EventSubnav/EventSubnav";
import banner from "../../assets/images/boatBanner.jpg";

const pokerRun = (props) => {
  const InnerTitle = (
    <div>
      <h1>Emerald Coast Poker Run</h1>
      {/* <h2>2 Days Only - {props.dates}</h2> */}
      <EventSubnav show3="false" show5="true" show6="true" show7="true" />
      <img src={banner} alt="Emerald Coast Poker Run - boats on the water"/>
    </div>
  );
  const FlyerContent = (
    <div>
      {/* <h5>The details below are from last year's Poker Run. We will update this information for the upcoming event as the details are finalized.</h5> */}

      <div className={classes.date1}>
        <h4>{props.start}</h4>
        <p><b>On-Site Registration &amp; Check-In</b> <br/>
          <span><b>The Island Hotel</b></span><br/>
          1500 Miracle Strip Pkwy SE<br/>
          Fort Walton Beach, Florida 32548<br/>
          <span>Okaloosa Island 2-7pm</span>
        </p>
        {/* <div className={[classes.fullRow, classes.bmargin1].join(' ')}>&middot;</div> */}
        <p><b>Captain's Party</b><br/> AJ's on the Bayou: 5:30-8:30pm<br/>
          <span>Live Music &middot; Complimentary Buffet &middot; Open Bar </span>
          {/* for VIP Registrations Only */}
        </p>
      </div>
  
      <div className={classes.date2}>
        <h4>{props.end}</h4>
        <p><b>Poker Run Stops Open:</b> 10am-4pm</p>
        <p className={classes.bmargin1}><b>Poker Run Party at The Block</b><br/><span>Fort Walton Beach &middot; Doors Open at 6pm</span></p>
        {/* <Link to="/party-tickets" className={[classes.ecLinkSm, classes.ecLink4].join(' ')}>Purchase Party Tickets Here</Link> */}
        {/* <div className={classes.spacer3} /> */}

        <div className={classes.spacer1} />
        <p><b>Gaming, Silent Auction, Music &amp; More!</b><br/>
          <span>Buffet &amp; Open Bar for all registered boaters &amp; ticket holders </span>
          {/*<br/> Buffet open for all registered boaters &amp; ticket holders<br/>
          <span>Open Bar for VIP Registered Boaters &amp; VIP Party Ticket Holders Only</span> */}
        </p>
      </div>
    </div>
  );

  return (
    <div className={classes.PokerRun}>
      <Helmet>
        <title>Emerald Coast Poker Run Details</title>
        <meta name="description" content="Take a look at the details for this year's Emerald Coast Poker Run, and get ready for a great weekend!" />
      </Helmet>

      <FlyerLay where="PokerRunPage" InnerTitle={InnerTitle} FlyerContent={FlyerContent} />
    </div>
  );
}
export default pokerRun;