import React from "react"
import { Helmet } from "react-helmet"
import classes from "./RegisterNow.module.scss"
import FlyerLay from "../../components/FlyerLay/FlyerLay"
import EventSubnav from "../../components/EventSubnav/EventSubnav"
import { Message } from "../../components/Shared/Announcement"
// import PHtext from "../../components/Shared/PhEventText"
// import phImage from "../../assets/images/speedboat.jpg"

// ***** KPKP -- UNCOMMENT WHEN REGISTRATION REOPENS - COMMENT WHEN CLOSED, and UNCOMMENT PHtext and fwRow
// TO AUTO POPULATE DISCOUNT:
// src="https://form.jotform.com/FORMIDNUM?coupon-input=FREEHAND" 


const registerNow = (props) => {
  const dateText = (<>{props.dates}<br/></>)

  const OuterTitle = (<div>
    <h1>Emerald Coast Poker Run<br/> {props.dates ? dateText : null}
      <span>Registration</span>
    </h1>
    <EventSubnav show1="false" show5="true" show6="true" addClass="altColor" />
    
    <div className={classes.clearfix}/>
    {/* <h4 className={classes.earlyBird}>Register before 8/10, and get $50 off VIP registration!<br/> Code: <span>50OFF</span></h4> */}
  </div>)  
  const FlyerContent = (
    <div>
      <div className={classes.clearfix}/><div className={classes.spacer1}/>
      <script type="text/javascript" src="https://form.jotform.com/jsform/241294076494160"></script> 
      <iframe 
        id="JotFormIFrame-241294076494160"
        title="Emerald Coast Poker Run Registration Form"
        onLoad={window.parent.scrollTo(0,0)}
        allowFullScreen={true}  
        allow="geolocation microphone camera" 
        src="https://form.jotform.com/241294076494160" 
        style={{minWidth:'100%',maxWidth:'100%',height:'auto'}}
      > 
      </iframe> 
    </div>
  ) 
  // const fwRow = (
  //   <div style={{ paddingTop:'20px' }}>
  //     <PHtext where="RegistrationPage" color="white" />
  //     <img src={phImage} alt="Emerald Coast Poker Run" />
  //   </div>
  // )

  return (
    <div className={classes.RegisterNow}>
      <Helmet>
        <title>Register for the Annual Emerald Coast Poker Run</title>
        <meta name="description" content="Register now, and get ready for this year's Emerald Coast Poker Run!" />
      </Helmet>
      <Message addClass="show940" />

      {/* <div className={classes.msgBox}>
        <h4>Attention:</h4>
        <p>Online registration for this year's event is now closed. However, those who want to participate can still register at <br/><b>AJ's on the Bayou from 2pm-5pm!</b></p>
      </div> */}

      {/* CLOSED: <FlyerLay where="RegisterPage" OuterTitle={OuterTitle} fwRow={fwRow} margin="0" />  */}
      {/* OPEN */}
      <FlyerLay where="RegisterPage" OuterTitle={OuterTitle} FlyerContent={FlyerContent} margin="0" /> 
    </div>
  )
}
export default registerNow